@import '../../../node_modules/bootstrap/scss/bootstrap';

.card-filter {
  user-select: none;
  cursor: pointer;
}

.image-container {
  position: relative;

  .product-image {
    object-fit: contain;
  }

  .image-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(54, 54, 54, 0.5);
    color: #ffffff;

    &:hover {
      opacity: 1;
    }
  }
}

.current-cart-sidebar {
  padding: 12px 0;
}

.product-cart-section {
  border: 1px solid lightgray;
  height: calc(100vh - 24px) !important;
}

.button-underline {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
}
