@import '../../../node_modules/bootstrap/scss/bootstrap';

.logo-text {
    letter-spacing: 1rem;
    font-weight: 400;
}

.navbar {
    z-index: 1050;
    * {
        color: $dark !important;
    }
    &.in-footer {
        * {
            color: $white !important;
        }
    }
}

.fullscreen-modal {
    height: 100vh;
    width: 100vh;
}
