.menu-dialog {
  width: 100%;
  position: fixed;
  height: 100vh;
  z-index: 500;
  margin-top: -122px;
  @media only screen and (max-width: 500px) {
    margin-top: -104px;
  }
}
