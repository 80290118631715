@import '../../../node_modules/bootstrap/scss/bootstrap';

.App-header {
  background-color: #000000b3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h2 {
  font-family: 'Roboto', sans-serif !important;
  text-transform: uppercase;
}

a {
  text-decoration: none !important;
}

h6 {
  text-transform: uppercase;
  letter-spacing: .25rem;
}

.text-content {
  font-size: .75rem;
  letter-spacing: .15rem;
  font-weight: 200;
  text-transform: none;
  line-height: 2rem;
}

.text-small {
  font-size: .8rem;
}

.text-lightgray {
  color: $gray-300 !important;
}

.loader {
  height: 100vh;
  width: 100%;
  background-color: #ffffff;
}

*:focus {
  outline: none;
}

// Underline

.underline {
  text-decoration: none !important;
  background-image: linear-gradient(currentColor, currentColor) !important;
  background-position: 0% 100% !important;
  background-repeat: no-repeat !important;
  background-size: 0% 1px !important;
  transition: background-size .3s !important;
}

.underline:hover, .underline:focus {
  background-size: 100% 1px !important;
}

