@import 'node_modules/bootstrap/scss/bootstrap';

.header-container {
  height: calc(100vh - 128px);
  * {
    color: $dark !important;
  }
}

.header-image {
  height: calc(100vh - (88px * 2) - 10rem);
}

.image1 {
  height: 180px;
}

.image2 {
  height: 400px;
}

@include media-breakpoint-up(lg) {
  .header-lg-image {
    height: calc(100vh - (136px * 2) - 10rem);
  }
}

@include media-breakpoint-up(sm) {
  .image1 {
    height: 350px;
  }
  .image2 {
    height: 600px;
  }
}

.menu-row {
  letter-spacing: 0.5rem;
  text-transform: uppercase;
}

.menu-list {
  padding: 0 !important;
  list-style: none;
}

.text-col, h4 {
  letter-spacing: 0.5rem;
  text-transform: uppercase;
}

img {
  object-fit: contain;
}
