@import '../../../node_modules/bootstrap/scss/bootstrap';

.checkout-product-image {
  height: 80px;
  width: 80px;
  object-fit: contain;
}

.previous-next-buttons {
  display: flex;
  justify-content: center;
  cursor: auto;
}

.button-day-disable {
  opacity: .5;
}

.form-group-aspect {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $secondary;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;

  &.disable {
    cursor: default;
    opacity: .5;
  }

  &.active {
    border: 1px solid $dark;
    color: $dark;
  }

  .radio-border {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid $secondary;
    display: flex;
    align-items: center;
    justify-content: center;

    .radio-center {
      background-color: $dark;
      border-radius: 8px;
      transition: height 0.15s ease-in-out, width 0.15s ease-in-out;
    }

    &.active {
      border: 1px solid $dark;

      .radio-center {
        width: 10px;
        height: 10px;
      }
    }
  }
}
