@import '../../../node_modules/bootstrap/scss/bootstrap';

.cart-product-image {
  @media only screen and (max-width: 500px) {
    height: 50px;
    width: 50px;
  }
  height: 100px;
  width: 100px;
  object-fit: contain;
}

.col-borders {
  border-top: 1px solid $light;
  border-bottom: 1px solid $light;
}

.cart-table-text {
  @media only screen and (max-width: 500px) {
    font-size: .5em;
  }
}
