body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// BOOTSTRAP CUSTOMS

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),    //4px
    2: ($spacer * .5),     //8px
    3: $spacer,            //16px
    4: ($spacer * 1.5),    //24px
    5: ($spacer * 3),      //48px
    6: ($spacer * 4),      //64px
    7: ($spacer * 5),      //80px
    8: ($spacer * 6.25),   //100px
    9: ($spacer * 7.5),    //120px
    10: ($spacer * 9.375)  //150px
  ),
  $spacers
);

$dark: #363636;

@import '../node_modules/bootstrap/scss/bootstrap';

html {
  font-size: .85rem;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: .85rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1rem;
  }
}
