@import '../../../node_modules/bootstrap/scss/bootstrap';

footer {
    min-height: 100vh;
    background-color: $dark;
    * {
        color: $light !important;
    }

    .container {
        height: 100vh;
    }

    .newsletter-input {
        input {
            padding-left: 0 !important;
            background-color: transparent;
            border: none;
            border-bottom: 1.2px solid #ffffff;
            border-radius: 0;
            &:focus {
                background-color: transparent;
                border-color: #ffffff;
            }
        }
        button {
            border: none;
            border-bottom: 1.2px solid #ffffff;
            border-radius: 0;
            &:hover, &:active {
                background-color: transparent !important;
                border-color: #ffffff !important;
            }
        }
    }

    .text-footer {
        font-size: .9rem;
        letter-spacing: .15rem;
        font-weight: 400;
        text-transform: uppercase;
    }
}
